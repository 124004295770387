exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buttons-store-buttons-tsx": () => import("./../../../src/pages/buttons/store-buttons.tsx" /* webpackChunkName: "component---src-pages-buttons-store-buttons-tsx" */),
  "component---src-pages-content-feature-halves-tsx": () => import("./../../../src/pages/content/feature_halves.tsx" /* webpackChunkName: "component---src-pages-content-feature-halves-tsx" */),
  "component---src-pages-content-feature-portrait-tsx": () => import("./../../../src/pages/content/feature_portrait.tsx" /* webpackChunkName: "component---src-pages-content-feature-portrait-tsx" */),
  "component---src-pages-content-feature-thirds-tsx": () => import("./../../../src/pages/content/feature_thirds.tsx" /* webpackChunkName: "component---src-pages-content-feature-thirds-tsx" */),
  "component---src-pages-content-features-tsx": () => import("./../../../src/pages/content/features.tsx" /* webpackChunkName: "component---src-pages-content-features-tsx" */),
  "component---src-pages-footer-desktop-download-tsx": () => import("./../../../src/pages/footer/desktop_download.tsx" /* webpackChunkName: "component---src-pages-footer-desktop-download-tsx" */),
  "component---src-pages-footer-footer-content-tsx": () => import("./../../../src/pages/footer/footer_content.tsx" /* webpackChunkName: "component---src-pages-footer-footer-content-tsx" */),
  "component---src-pages-footer-footer-tsx": () => import("./../../../src/pages/footer/footer.tsx" /* webpackChunkName: "component---src-pages-footer-footer-tsx" */),
  "component---src-pages-header-header-tsx": () => import("./../../../src/pages/header/header.tsx" /* webpackChunkName: "component---src-pages-header-header-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-utils-download-bar-tsx": () => import("./../../../src/pages/utils/download_bar.tsx" /* webpackChunkName: "component---src-pages-utils-download-bar-tsx" */),
  "component---src-pages-widgets-dark-mode-widget-tsx": () => import("./../../../src/pages/widgets/dark_mode_widget.tsx" /* webpackChunkName: "component---src-pages-widgets-dark-mode-widget-tsx" */),
  "component---src-pages-widgets-podcasts-carousel-tsx": () => import("./../../../src/pages/widgets/podcasts_carousel.tsx" /* webpackChunkName: "component---src-pages-widgets-podcasts-carousel-tsx" */),
  "component---src-pages-widgets-podcasts-widget-tsx": () => import("./../../../src/pages/widgets/podcasts_widget.tsx" /* webpackChunkName: "component---src-pages-widgets-podcasts-widget-tsx" */)
}

